import React,{FC} from "react";
import classnames from "classnames";
import styles from './ItemContent.module.css';
import { IContent } from "../../types/interfaces";
import ContentBtn from "../ContentBtn/ContentBtn";


const ItemContent:FC<IContent> = ({title, text, style, styleTitle, textBtn, refBtn}) => {
    return (
        <div className={classnames(styles.introPreviewSide, style)}>
            <h2 className={classnames(styles.previewSideTitle, styleTitle)}>{title}</h2>
            <div className={styles.previewSideText}><p>{text}</p></div>
            <ContentBtn style={styles.btnLink} text={textBtn!} reference={refBtn}/>
        </div>
    );
};

export default ItemContent;