import React from "react";
import styles from './ContactsPage.module.css';
import classnames from 'classnames';
import SocialLinks from "../../components/SocialLinks/SocialLinks";

const ContactsPage = () => {
    return(
        <section className={styles.contactSection}>
            <div className={styles.SectionContent}>
                <h2 className={styles.contentTitle}>Contact</h2>
                <div className={styles.wrapperText}>
                    <div className={styles.contentTextW}>
                    <p className={styles.contentText}>Our organization is designed to help and helps by designing. We develop reconstruction projects for damaged and destroyed residential and commercial buildings in Ukraine. Our group of inspired architects, engineers, and other volunteers working hard to provide roof to those who lost their homes.</p>
                    </div>
                    <div className={classnames(styles.wrapperLinks, styles.linksMargin)}>
                        <h4 className={styles.linkTitle}>Email for contact</h4>
                        <ul>
                            <li className={styles.emailTitle}>International Reconstruction Volunteer team</li>
                            <li><a className={styles.contentLink} href="mailto:inter.rvg@gmail.com">inter.rvg@gmail.com</a></li>
                        </ul>
                    </div>
                    <SocialLinks isAbout={false}/>
                </div>
            </div>
        </section>
    );
};

export default ContactsPage;