import React from "react";
import HomePage from "../pages/HomePage/HomePage";
import { Route, Routes } from "react-router-dom";
import routes from "../utils/routes";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import JoinPage from "../pages/JoinPage/JoinPage";
import ContactsPage from "../pages/ContactsPage/ContactsPage";
import AboutPage from "../pages/AboutPage";



const App = () => {
    return(
        <>
        <Header/>
        <main className='main'>
        <Routes>
            <Route path={routes.homePage} element={<HomePage/>}/>
            <Route path={routes.joinPage} element={<JoinPage/>}/>
            <Route path={routes.contactPage} element={<ContactsPage/>}/>
            <Route path={routes.aboutPage} element={<AboutPage/>}/>
        </Routes>
        </main>
        <Footer/>
        </>
    );
};

export default App;