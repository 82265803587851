import React from "react";
import styles from './HomePage.module.css';
import PreviewImg from '../../static/previewImg1.png';
import PreviewImg2 from '../../static/previewImg2.png';
import PreviewImg3 from '../../static/previewImg3.png';
import communityImg from '../../static/communityImg.png';
import ItemContent from "../../components/ItemContent/ItemContent";
import GalleryList from "../../components/GalleryList/GalleryList";
import ContentBtn from "../../components/ContentBtn/ContentBtn";

const HomePage = () => {
    const data = [
        {
            img: PreviewImg2,
            content: {
                title: 'Local Partners',
                textBtn: 'About us',
                refBtn: '/about',
                text: 'By cooperating with local authorities and volunteers, nothing escapes our attention. Our local partners and team members inform us consistently about the work going on place that ensures efficient operation.',
            }
        },
        {
            img: PreviewImg3,
            content: {
                title: 'Watch us',
                textBtn: 'About us',
                refBtn: '/about',
                sub: true,
                subtitle: 'Nigel Macias; Vertebrae Dormitory Urbino, Italy',
                text: 'We remain transparent to win your trust! Watch our work on social media where we publish every step of project development so that you can see the progress. If you want to become a member of our media team, please fill out the Join Us form. We would greatly appreciate any media support from you!',
            }
        },
    ];
    return (
    <>
        {/* <main className={styles.homeMain}> */}
            <div className={styles.homeContent}>
                <section className={styles.introSection}>
                    <div className={styles.introContent}>
                        <h1 className={styles.introTitle}>We are designed to help and help by designing</h1>
                        <div className={styles.introText}><p>We develop reconstruction projects for damaged and destroyed residential and commercial buildings in Ukraine. Our group of inspired architects, engineers, and other volunteers working hard to provide roof to those who lost their homes. IRVG is trying to attract attention of international community to the housing problem in Ukraine. Your engagement can save someone’s life!</p>
                        <ContentBtn reference="/about" text="About us" style={styles.previewBtn}/>
                        </div>
                        <div className={styles.introPlaceholder}></div>
                        <ItemContent style={styles.galleryItem1} textBtn="Join us" refBtn="/join" styleTitle={styles.galleryItemTitle} title="Cooperation" text="Join us and help our projects come true! Our team is welcoming energized and motivated volunteers! Every possible professional or financial help is priceless for the projects we do."/>
                        <div className={styles.previewSideImg}>
                            <div className={styles.wrapperImgTitle}>
                            <img className={styles.previewSideImgSource} src={PreviewImg} alt="previewSideImg" />
                            <span className={styles.previewSideImgSubtitle}>Kalejaye Obafemi Fuad; Caleb University, Lagos State, Nigeria</span>
                            </div>
                        </div>
                    </div>
                    <GalleryList list={data}/>
                </section>
                <section className={styles.communitySection}>
                    <h2 className={styles.communitySectionTitle}>Still have questions? Ask us!</h2>
                    <img className={styles.communitySectionImg} src={communityImg} alt="communityImg" />
                    <div className={styles.communitySectionContent}>
                        <p className={styles.communitySectionContentText}>Let’s restore Ukraine together! Our team is always here for you to give an answer for your questions. Please contact us any time.</p>
                        <ContentBtn reference="/contact" style={styles.linkBtn} text="Contact us"/>
                    </div>
                </section>
            </div>
        {/* </main> */}
        </>
    );
};

export default HomePage;