import React from "react";
import styles from './Header.module.css';
import { Link, NavLink } from "react-router-dom";
import classnames from "classnames";

const Header = () => {
    const onClickFn = () => {
        document.querySelector('html')?.classList.toggle(styles.hidden);
        document.querySelector('#burgerContent')?.classList.toggle(styles.menuOpen);
        document.querySelector('#burgerContent1')?.classList.toggle(styles.beforeOpen);
        document.querySelector('#burgerContent2')?.classList.toggle(styles.afterOpen);
        document.querySelector('#navMenu')?.classList.toggle(styles.navMenuOpen);
    }
    const onClickLogo = () => {
        document.querySelector('html')?.classList.remove(styles.hidden);
        document.querySelector('#burgerContent')?.classList.remove(styles.menuOpen);
        document.querySelector('#burgerContent1')?.classList.remove(styles.beforeOpen);
        document.querySelector('#burgerContent2')?.classList.remove(styles.afterOpen);
        document.querySelector('#navMenu')?.classList.remove(styles.navMenuOpen);
    }
    return(
        <header className={styles.header}>
            <div className={styles.headerContent}>
            <Link className={classnames(styles.headerLogo, styles.desktopLogo)} to={'/'}>
            <h1>IRVG
            <span className={styles.logoSubtitle}>International Reconstruction Volunteer Group</span>
            </h1></Link>
            <Link onClick={onClickLogo} className={classnames(styles.headerLogo, styles.mobileHeaderLogo)} to={'/'}>
            <h1>IRVG
            <span className={styles.logoSubtitle}>International Reconstruction Volunteer Group</span>
            </h1></Link>
            <nav className={styles.headerNavigation}>
                <ul className={styles.headerNavigationList}>
                    <NavLink className={({isActive}) => isActive ? classnames(styles.navigationItem, styles.navigationItemActive, ) : styles.navigationItem} to={'/about'}>
                    <li>About <span className={styles.ItemHoverEl}>us</span></li>
                    </NavLink>
                    <NavLink className={({isActive}) => isActive ? classnames(styles.navigationItem, styles.navigationItemActive, ) : styles.navigationItem} to={'/contact'}>
                    <li>Contact <span className={styles.ItemHoverEl}>us</span></li>
                    </NavLink>
                    <NavLink className={({isActive}) => isActive ? classnames(styles.navigationItem, styles.navigationItemActive, ) : styles.navigationItem} to={'/join'}>
                    <li>Join <span className={styles.ItemHoverEl}>us</span></li>
                    </NavLink>
                </ul>
            </nav>
            <button onClick={onClickFn} className={styles.headerBurger} type="button">
            <div className={styles.headerBurgerContent}>
              <span id="burgerContent1" className={styles.burgerContent1}></span>
              <span id="burgerContent" className={styles.burgerContent}></span>
              <span id="burgerContent2" className={styles.burgerContent2}></span>
            </div>
          </button>
            </div>
            <nav id="navMenu" className={classnames(styles.headerNavigation, styles.mobileNav)}>
                <ul className={classnames(styles.headerNavigationList, styles.mobileNavList)}>
                    <NavLink onClick={onClickFn} className={({isActive}) => isActive ? classnames(styles.navigationItem, styles.navigationItemActive, ) : styles.navigationItem} to={'/about'}>
                    <li>About <span className={styles.ItemHoverEl}>us</span></li>
                    </NavLink>
                    <NavLink onClick={onClickFn} className={({isActive}) => isActive ? classnames(styles.navigationItem, styles.navigationItemActive, ) : styles.navigationItem} to={'/contact'}>
                    <li>Contact <span className={styles.ItemHoverEl}>us</span></li>
                    </NavLink>
                    <NavLink onClick={onClickFn} className={({isActive}) => isActive ? classnames(styles.navigationItem, styles.navigationItemActive, ) : styles.navigationItem} to={'/join'}>
                    <li>Join <span className={styles.ItemHoverEl}>us</span></li>
                    </NavLink>
                </ul>
            </nav>
        </header>
    );
};

export default Header;