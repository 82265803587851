import React from "react";
import styles from './Footer.module.css';

const Footer = () => {
    return(
        <footer className={styles.footer}>
            <div className={styles.footerList}>
                <ul className={styles.footerListContent}>
                <li className={styles.listContentItem}></li>
                    <li className={styles.listContentItem}><a href="#">Privacy</a></li>
                    <li className={styles.listContentItem}><a href="#">Policy</a></li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;