import React, {useState, useRef} from "react";
import styles from './JoinPage.module.css';
import classnames from 'classnames';
import emailjs from '@emailjs/browser';

const JoinPage = () => {
    const formRef = useRef<HTMLFormElement>(null);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comChannel, setComChannel] = useState('');
    const [about, setAbout] = useState('');
    const sendEmail = (event: React.FormEvent) => {
        event.preventDefault();
        let typeEvent = true;
        if(!name || !surname || !email || !phone || !comChannel) {
          typeEvent = window.confirm(`You miss these fields: ${!name ? 'name, ' : ''}${!surname ? 'last name, ' : ''}${!email ? 'email, ' : ''}${!phone ? 'phone, ': ''}${!comChannel ? 'communicational channel' : ''}. Do you want to continue?`);
        }
        if(typeEvent) {
          emailjs
          .sendForm('service_sy5nuuv', 'template_rm3r3xh', formRef.current!, {
            publicKey: 'Hvmuvx2tNGJhz2ODl',
          })
          .then(
            () => {
              alert('Your message is send, we will contact you soon!');
              setName('');
              setSurname('');
              setEmail('');
              setPhone('');
              setComChannel('');
              setAbout('');
            },
            (error) => {
              alert(`FAILED...${error.text} try again`);
            },
          );
        }
      };
    return(
        <section className={styles.JoinSection}>
            <div className={styles.sectionContent}>
                <h2 className={styles.sectionTitle}>Join our group</h2>
                <p className={styles.sectionText}>Our organization is designed to help and helps by designing. We develop reconstruction projects for damaged and destroyed residential and commercial buildings in Ukraine. Our group of inspired architects, engineers, and other volunteers working hard to provide roof to those who lost their homes.</p>
                <form onSubmit={sendEmail} ref={formRef} className={styles.sectionForm} action="#">
                <h4 className={styles.sectionSubtitle}>Fill the form below to take part in our project</h4>
                    <div className={styles.wrapperInputs}>
                    <input value={name} onChange={(event) => setName(event.target.value)} className={styles.formInput} placeholder="Your name" name="name" type="text" />
                    <input value={surname} onChange={(event) => setSurname(event.target.value)} className={styles.formInput} placeholder="Your last name" name="surname" type="text" />
                    </div>
                    <div className={styles.wrapperInputs}>
                    <input value={email} onChange={(event) => setEmail(event.target.value)} className={styles.formInput} placeholder="Your email" name="email"  type="email" />
                    <input value={phone} onChange={(event) => setPhone(event.target.value)} className={styles.formInput} placeholder="Your phone" name="phone" type="phone" />
                    </div>
                    <input value={comChannel} onChange={(event) => setComChannel(event.target.value)} className={styles.formInput} placeholder="Another communication channel" name="communicational_channel" type="text" />
                    <div className={styles.wrapperTextarea}>
                    <textarea maxLength={200} value={about} onChange={(event) => setAbout(event.target.value)} className={classnames(styles.formInput, styles.textArea)} placeholder="Please tell us how can you to help*" required name="cv" id="cv">
                    </textarea>
                    <div className={styles.textLengths}>
                            <span className={styles.lengthElement}>{about.length}</span>
                            <span className={styles.lengthElement}>/200</span>
                    </div>
                    </div>
                    <input className={styles.submitBtn} type="submit" value="Send" />
                </form>
            </div>
        </section>
    );
};

export default JoinPage;