import React,{FC} from "react";
import styles from './GalleryList.module.css';
import { IList } from "../../types/interfaces";
import GalleryItem from "../GalleryItem/GalleryItem";

const GalleryList:FC<IList> = ({list}) => {
    return(
        <>
        {list.map((item, index) => {
            return(
                <GalleryItem key={index} img={item.img} content={item.content}/>
            );
        })}
        </>
    );
};

export default GalleryList;