import React, {FC} from "react";
import styles from './ContentBtn.module.css';
import { IContentBtn } from "../../types/interfaces";
import classnames from 'classnames';
import { Link } from "react-router-dom";

const ContentBtn:FC<IContentBtn> = ({text, style, reference}) => {
    return(
        <Link className={classnames(styles.previewSideBtn, style)} to={reference!}>{text}</Link>
    );
}

export default ContentBtn;