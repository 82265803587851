import React, { FC } from "react";
import styles from './SocialLinks.module.css';
import InstIcon from '../../static/instIcon.svg';
import FbIcon from '../../static/FbIcon.svg';
import TwIcon from '../../static/twIcon.svg';
import { ISocial } from "../../types/interfaces";
import classnames from 'classnames';

const SocialLinks: FC<ISocial> = ({ isAbout }) => {
    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        const target = event.target as HTMLElement;
        if(target.nodeName === 'LI' || target.nodeName === "A" || target.nodeName === "IMG") {
            alert('All our updates will be published on our social media soon!');
        }
    };
    return (
        <div className={styles.wrapperLinks}>
            <h4 className={styles.linkTitle}>Social Media</h4>
            {isAbout ? <ul onClick={onClick} className={classnames(styles.social, styles.socialAbout)}>
                <li className={styles.socialLink}><a className={styles.contentLink} href="#"><img src={InstIcon} alt="icon" /></a></li>
                <li className={styles.socialLink}><a className={styles.contentLink} href="#"><img src={FbIcon} alt="icon" /></a></li>
                <li className={styles.socialLink}><a className={styles.contentLink} href="#"><img src={TwIcon} alt="icon" /></a></li>
            </ul> : <ul onClick={onClick} className={styles.social}>
                <li className={styles.socialLink}><a className={styles.contentLink} href="#">Instagram</a><img src={InstIcon} alt="icon" /></li>
                <li className={styles.socialLink}><a className={styles.contentLink} href="#">Facebook</a><img src={FbIcon} alt="icon" /></li>
                <li className={styles.socialLink}><a className={styles.contentLink} href="#">Twitter</a><img src={TwIcon} alt="icon" /></li>
            </ul>}
        </div>
    );
};

export default SocialLinks;