import React from "react";
import styles from './HomePage/HomePage.module.css';
import ContentBtn from "../components/ContentBtn/ContentBtn";
import ItemContent from "../components/ItemContent/ItemContent";
import PreviewImg4 from '../static/previewImg4.png';
import classnames from 'classnames';
import SocialLinks from "../components/SocialLinks/SocialLinks";
import GalleryList from "../components/GalleryList/GalleryList";
import PreviewImg12 from '../static/previewImg12.png';

const AboutPage = () => {
    const data = [
        {
            img: PreviewImg12,
            content: {
                title: 'Our Results',
                textBtn: 'Explore it',
                refBtn: 'https://drive.google.com/drive/folders/1VnHSFLCqkVTGQvdk5vTOd392qEyQ79US?usp=share_link',
                text: 'To ensure transparency in our organization, we are committed to providing bi-weekly reports on our progress and the work we have done. You can follow the link below to see our project development.',
            }
        },
    ];
    return (
        <section className={styles.introSection}>
            <div className={styles.introContent}>
                <h1 className={styles.introTitle}>About us</h1>
                <div className={styles.introText}><p>Our organization is designed to help and helps by designing. We develop reconstruction projects for damaged and destroyed residential and commercial buildings in Ukraine. Our group of inspired architects, engineers, and other volunteers working hard to provide roof to those who lost their homes.</p>
                    <ContentBtn text="Join us" style={styles.previewBtn} />
                    <SocialLinks isAbout/>
                </div>
                <div className={styles.introPlaceholder}></div>
                <ItemContent refBtn="/join" textBtn="Join us" style={styles.galleryItem1} styleTitle={styles.galleryItemTitle} title="Our team" text="Join us and help our projects come true! Our team is welcoming energized and motivated volunteers! Every possible professional or financial help is priceless for the projects we do." />
                <div className={styles.previewSideImg}>
                <div className={styles.wrapperImgTitle}>
                <img className={classnames(styles.previewSideImgSource, styles.sourceImgTop)} src={PreviewImg4} alt="previewSideImg" />                            </div>
                </div>
            </div>
            <GalleryList list={data}/>
        </section>
    );
};

export default AboutPage;