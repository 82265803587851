import React,{FC} from "react";
import styles from './GalleryItem.module.css';
import ItemContent from "../ItemContent/ItemContent";
import { IItem } from "../../types/interfaces";

const GalleryItem:FC<IItem> = ({img, content}) => {
    return(
        <div className={styles.galleryItem}>
            <ItemContent title={content.title} textBtn={content.textBtn} refBtn={content.refBtn} text={content.text}/>
            {content.sub ? <div className={styles.wrapperImg}><img className={styles.galleryImg} src={img} alt="galleryImg" />
            <span className={styles.imgSub}>{content.subtitle}</span></div> : <img className={styles.galleryImg} src={img} alt="galleryImg" />}
        </div>
    );
};

export default GalleryItem;